<template>
  <div :id="'TopicInfo'+topicData.id" style="margin: 0 20px;">
    <div>
      <div v-if="!alone"><span>{{topicData.quest_num_title}}</span><span>（{{topicData.grade}}分）</span></div>
      <div class="topicTitle"><span>{{ topicData.qid }}</span><span>（单选题）</span><span class="title" v-html="topicData.title"></span><span>（{{topicData.real_grade?topicData.real_grade:topicData.grade}}分）</span></div>
      <div style="margin-bottom: 1rem;" v-code-html="topicData.description"></div>
    </div>
    <template v-if="optionsData.isAnswer">
      <el-radio-group :value="topicData.answer[0] || topicData.answer" disabled v-if="(topicData.options || []).length">
        <div class="group-item" v-for="(v, i) in topicData.options" :key="i">
          <el-radio :label="v.code" v-if="v.title_type+''!=='1'">
            <span class="el-radio__input">{{ v.code }}、</span><span v-html="v.title" ></span>
          </el-radio>
          <el-radio :label="v.code" v-else>
            <span class="el-radio__input">{{ v.code }}、</span>
            <el-image style="width: 150px;" :src="v.title" ></el-image>
          </el-radio>
        </div>
      </el-radio-group>
      <div class="analysis-box" v-if="resultShow">
        <div>参考答案：{{ (topicData.answer || []).join(' 、 ') }}</div>
        <div>我的答案：{{ (value instanceof Array ? value : (value ? [value] : (value||[]))).join(' 、 ') }}
          <template v-if="topicData.remark">
            <i class="el-icon-check check_record" v-if="topicData.remark.label==='对'||topicData.remark==='对'"></i>
            <i class="el-icon-close close_record" v-if="topicData.remark.label==='错'||topicData.remark==='错'"></i>
          </template>
        </div>
      </div>
      <div class="analysis-box" v-if="!(topicData.type+'' === '3')">
        <div>答案解析：
          <span v-code-html="topicData.analysis" v-if="topicData.analysis"></span>
          <span v-else style="color:#979797">暂无解析</span>
        </div>
      </div>
    </template>
    <template v-if="!optionsData.isAnswer">
      <el-radio-group v-model="selectAnswer"  @change="handleEmit('change' , ...arguments)">
        <div class="group-item" v-for="(v, i) in topicData.options" :key="i">
          <el-radio :label="v.code" v-if="v.title_type+''!=='1'" class="radio">
            <span class="el-radio__label">{{ v.code }}、</span><span v-html="v.title" ></span>
          </el-radio>
          <el-radio :label="v.code" v-else>
            <span class="el-radio__label">{{ v.code }}、</span>
            <el-image style="width: 150px;" :src="v.title" ></el-image>
          </el-radio>
        </div>
      </el-radio-group>
    </template>
    <TopicOperate :id="topicData.id" :topic_data="topicData" :title="topicData.title" v-if="discussShow && !(topicData.type+'' === '3')"></TopicOperate>
  </div>
</template>

<script>
import TopicOperate from "@/components/TopicOperate/TopicOperate.vue"
export default {
  components: { TopicOperate },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value:{
      default(){return null;}
    },
    show_answer:{
      default: () => true
    },
    alone: {
      default: () => false
    },
    getAnswer:'',
    optionsData: {
      topicData: {},
      isAnswer: true,
    },
    stagingAnswer2: {
				default: () => '',
			},
      stagingAnswerArr: {
				default: () => '',
			},
       // 是否显示判题结果
			resultShow: {
				default: () => false,
			},
    discussShow: {
      default: () => false
    }
  },
  data () {
    return {
      selectAnswer:'',
    }
  },
  computed: {
    topicData () {
      return this.optionsData.topicData || {}
    }
  },
  watch: {
    value (val) {
      console.log(val,"valvallll")
      if(val && val !== this.selectAnswer){
        this.selectAnswer = val;
      }
      this.$emit('change' , this.selectAnswer);
    },
  },
  created () {
    // console.log(this.optionsData.topicData,"this.optionsData.topicData")
    console.log(this.stagingAnswer,"this.t一题多问单选题")
    this.handleEmitChange()
    if(this.optionsData.topicData.last_answer){
      this.selectAnswer=JSON.parse(this.optionsData.topicData.last_answer).join("")
    }
    //存在上一次答案
    if(this.stagingAnswer2){
      console.log(this.stagingAnswer2,'stagingAnswer2')
      this.selectAnswer=this.stagingAnswer2;
      console.log(this.selectAnswer,"this.inputAnswerstagingAnswer2")
    }
    if(this.stagingAnswerArr){
      console.log(this.stagingAnswerArr,'t一题多问单选题')
      this.selectAnswer=this.stagingAnswerArr;
    }
    console.log(this.value,"valuevaluevalue")
    if(this.value && this.value !== this.selectAnswer){
      this.selectAnswer = this.value;
    }
  },
  methods:{
    handleEmitChange() {
      console.log(this.selectAnswer,"~~~~~~~~~~~");
      this.$emit("change", this.selectAnswer);
    },
  }
};
</script>

<style lang="less" scoped>
.topicTitle {
  margin-bottom:1rem;
  font-size: 16px;
  color: #00957e;
  font-weight: 600;
  line-height: 38px;
  white-space:pre-wrap;
  .title :first-child{
    display: inline-block;
  }
}
.group-item {
  margin: 1rem 0;
}
::v-deep {
  .el-radio{
    display: flex;
    align-items: flex-start;
  }
  .el-radio__label{
    display: flex;
  }
  .el-radio.is-checked *{
    color: var(--color-primary);
  }
  .el-radio,.el-radio *{
    align-items: self-start;
    line-height: 1.45;
  }
}
</style>