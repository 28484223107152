<template>
  <div class="filling" :id="'TopicInfo'+topicData.id">
    <div>
      <div v-if="!alone"><span>{{topicData.quest_num_title}}</span><span>（{{topicData.grade}}分）</span></div>
      <div class="topicTitle"><span>{{ topicData.qid }}</span><span>（填空题）</span><span class="title" v-html="topicData.title"></span><span>（{{topicData.real_grade?topicData.real_grade:topicData.grade}}分）</span></div>
      <div style="margin-bottom: 1rem;" v-code-html="topicData.description"></div>
    </div>
    <template v-if="optionsData.isAnswer">
      <slot name="showAnswer" v-bind="topicData">
        <div class="analysis-box" v-if="resultShow">
          <div>参考答案：
            <span style="background-color: #f5f5f5;padding: 0 5px;border-radius: 2px;" v-for="(v,i) in (topicData.answer || [])" :key="i" :style="{marginRight:'10px'}">
              {{ typeof v === 'object' ? v.answer : v }}
            </span>
          </div>
          <div>我的答案：
            <span style="background-color: #f5f5f5;padding: 0 5px;border-radius: 2px;" v-for="(v,i) in (value || [])" :key="i" :style="{marginRight:'10px'}">
              {{ v }}
            </span>
            <template v-if="topicData.remark">
              <i class="el-icon-check check_record" v-if="topicData.remark.label==='对'||topicData.remark==='对'"></i>
              <i class="el-icon-close close_record" v-if="topicData.remark.label==='错'||topicData.remark==='错'"></i>
            </template>
          </div>
        </div>
        <div class="analysis-box" v-if="!(topicData.type+'' === '3')">
          <div>答案解析：
            <span v-code-html="topicData.analysis" v-if="topicData.analysis"></span>
            <span v-else style="color:#979797">暂无解析</span>
          </div>
        </div>
      </slot>
    </template>
    <template v-else>
      <el-row :gutter="30" style="margin-top: 40px">
        <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="8"
            :xl="8"
            v-for="(v,i) in topicData.answer"
            :key="i"
            style="display: flex;"
        >
          <div class="text" v-if="topicData.answer.length>1">{{i+1}}、</div>
          <el-input type="textarea" autosize placeholder="请输入内容" v-model="inputAnswer[i]" size="mini" @change="handleEmit('change' , ...arguments)" > </el-input>
          <!-- <el-input type="textarea" autosize placeholder="请输入内容" v-model="inputAnswer" size="mini" @change="handleEmit('change' , ...arguments)" > </el-input> -->
        </el-col>
      </el-row>
    </template>
    <TopicOperate :id="topicData.id" :topic_data="topicData" :title="topicData.title" v-if="discussShow && !(topicData.type+'' === '3')"></TopicOperate>
  </div>
</template>

<script>
import TopicOperate from "@/components/TopicOperate/TopicOperate.vue"
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    TopicOperate
  },
  props: {
    value:{
      default(){return null;}
    },
    optionsData: {
      topicData: {},
      isAnswer: true,
    },
    stagingAnswer2: {
				default: () => '',
			},
      stagingAnswerArr: {
				default: () => '',
			},
      // 是否显示判题结果
			resultShow: {
				default: () => false,
			},
    alone: {
      default: () => false
    },
    discussShow: {
      default: () => false
    }
  },
  data () {
    return {
      inputAnswer: [],
      titleData: '',
    }
  },
  computed: {
    topicData () {
      return this.optionsData.topicData || {}
    }
  },
  watch: {
    value (val) {
      // console.log(val,"TKTval")
      if(val && val !== this.inputAnswer){
        let inputAnswer;
        if(val instanceof Array){
          inputAnswer = [...val];
        }else{
          inputAnswer = val.split('、');
        }
        if(this.$tool.obj_equal(inputAnswer , this.inputAnswer)){
          this.inputAnswer = inputAnswer;
        }
      }
      // console.log(this.inputAnswer,"this.inputAnswer")
      this.$emit("change", this.inputAnswer);

    },
  },
  created () {
    console.log(this.resultShow,"resultShowresultShowresultShow")
    // console.log(this.value,"value");
    console.log(this.stagingAnswerArr,"听空stagingAnswerList?.stagingAnswerList[b]")
    this.handleEmitChange()
    if(this.optionsData.topicData.last_answer){
      // console.log('111')
      this.inputAnswer=JSON.parse(this.optionsData.topicData.last_answer);
      // console.log(this.inputAnswer,"this.inputAnswer")
    }
    if(this.stagingAnswer2){
      // console.log(this.stagingAnswer2,'stagingAnswer2')
      this.inputAnswer=this.stagingAnswer2.split('、');
      // console.log(this.inputAnswer,"this.inputAnswerstagingAnswer2")
    }
    // 综合题
    if(this.stagingAnswerArr){
       console.log(this.stagingAnswerArr,'填空题')
      this.inputAnswer=this.stagingAnswerArr.split('、');
      // console.log(this.inputAnswer,"t一题多问答案数组")
    }
    if(this.value){
      // console.log(this.value,"v132132")
      if(this.value instanceof Array){
        this.inputAnswer = [...this.value];
      }else{
        this.inputAnswer = this.value.split('、');
      }
    }
  },
  methods: {
    handleEmitChange(){
      this.$emit('change',this.inputAnswer);
    },
  },
};
</script>

<style lang="less" scoped>

.topicTitle {
  margin: 1rem 0;
  font-size: 16px;
  color: #00957e;
  font-weight: 600;
  line-height: 38px;
  white-space:pre-wrap;
  .title :first-child{
    display: inline-block;
  }
}
.filling {
  margin: 0 20px;
  .text{
    font-size: 14px;
    line-height: 1;
    padding: 6px 0;
  }
  ::v-deep{
    .el-textarea__inner{
      color: #000;
      font-size: 14px;
      line-height: 1;
    }
  }
  input {
    border: 0;
  }
  input:focus {
    outline: none;
  }
}
</style>