import { render, staticRenderFns } from "./BCT.vue?vue&type=template&id=f8514ee2&scoped=true&"
import script from "./BCT.vue?vue&type=script&lang=js&"
export * from "./BCT.vue?vue&type=script&lang=js&"
import style0 from "./BCT.vue?vue&type=style&index=0&id=f8514ee2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8514ee2",
  null
  
)

export default component.exports