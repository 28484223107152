<template>
  <div class="filling" :id="'TopicInfo'+topicData.id" style="margin: 0 20px;">
    <div>
      <div v-if="!alone"><span>{{topicData.quest_num_title}}</span><span>（{{topicData.grade}}分）</span></div>
      <div class="topicTitle"><span>{{ topicData.qid }}</span><span>（判断题）</span><span class="title" v-html="topicData.title"></span><span>（{{topicData.real_grade?topicData.real_grade:topicData.grade}}分）</span></div>
      <div style="margin-bottom: 1rem;" v-code-html="topicData.description"></div>
    </div>

    <template v-if="optionsData.isAnswer">
      <el-radio-group :value="topicData.answer[0] || topicData.answer" disabled v-if="(topicData.options || []).length">
        <div class="group-item" v-for="(v, i) in topicData.options" :key="i">
          <el-radio :label="v.code">{{ v.title }}</el-radio>
        </div>
      </el-radio-group>
      <slot name="showAnswer" v-bind="topicData">
        <div class="analysis-box" v-if="resultShow">
          <div>参考答案：{{((topicData.options || []).filter(d=>(topicData.answer||'').indexOf(d.code) > -1)[0] || {}).title || ''}}</div>
          <div style="align-items: center;display: flex;">
            我的答案：{{((topicData.options || []).filter(d=>(value||'').indexOf(d.code) > -1)[0] || {}).title || ''}}
            <template v-if="topicData.remark">
              <i class="el-icon-check check_record" v-if="topicData.remark.label==='对'||topicData.remark==='对'"></i>
              <i class="el-icon-close close_record" v-if="topicData.remark.label==='错'||topicData.remark==='错'"></i>
            </template>
          </div>
        </div>
        <div class="analysis-box" v-if="!(topicData.type+'' === '3')">
          <div>答案解析：
            <span v-code-html="topicData.analysis" v-if="topicData.analysis"></span>
            <span v-else style="color:#979797">暂无解析</span>
          </div>
        </div>
      </slot>
    </template>
    <template v-else>
      <el-radio-group v-model="answer" @change="handleEmit('change' , ...arguments)">
        <div class="group-item" v-for="(v, i) in topicData.options" :key="i">
          <el-radio :label="v.code">{{ v.title }}</el-radio>
        </div>
      </el-radio-group>
    </template>
    <TopicOperate :id="topicData.id" :topic_data="topicData" :title="topicData.title" v-if="discussShow && !(topicData.type+'' === '3')"></TopicOperate>
  </div>
</template>

<script>
import TopicOperate from "@/components/TopicOperate/TopicOperate.vue"
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value:{
      default(){return null;}
    },
    alone: {
      default: () => false
    },
    optionsData: {
      topicData: {},
      isAnswer: true,
    },
    stagingAnswer2: {
				default: () => '',
			},
      // 暂存传进来的
      stagingAnswerArr: {
				default: () => '',
			}, 
      // 是否显示判题结果
			resultShow: {
				default: () => false,
			},
    discussShow: {
      default: () => false
    }
  },
  components: {
    TopicOperate
  },
  data () {
    return {
      answer: '',
    }
  },
  computed: {
    topicData () {
      return this.optionsData.topicData || {}
    }
  },
  watch: {
    value (val) {
      if(val && val !== this.answer){
        this.answer = val;
      }
      this.$emit("change", this.answer);
    },
  },
  created () {
    console.log(this.optionsData.topicData,"this.optionsData.topicData ")
    this.handleEmitChange()
    if(this.optionsData.topicData.last_answer){
      this.answer=JSON.parse(this.optionsData.topicData.last_answer).join("")
    }
    if(this.stagingAnswer2){
      console.log(this.stagingAnswer2,'stagingAnswer2')
      this.answer=this.stagingAnswer2;
      console.log(this.answer,"this.inputAnswerstagingAnswer2")
    }
    if(this.stagingAnswerArr){
      console.log(this.stagingAnswerArr,'stagingAnswerArr一题多问答案数组')
      this.answer=this.stagingAnswerArr;
      console.log(this.answer,"t一题多问答案数组")
    }
    if(this.value && this.value !== this.answer){
      this.answer = this.value;
    }
  },
  methods:{
    handleEmitChange() {
      console.log(this.answer,"~~~~~~~~~~~");
      this.$emit("change", this.answer);
    },
  }
}
</script>

<style lang="less" scoped>
.group-item {
  margin: 1rem 0;
}
::v-deep {
  .el-radio__input.is-checked + .el-radio__label {
    color: #00957e;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: #00957e;
    border-color: #00957e;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #00957e;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #00957e;
    border-color: #00957e;
  }
}
.topicTitle {
  margin: 1rem 0;
  font-size: 16px;
  color: #00957e;
  font-weight: 600;
  line-height: 38px;
  white-space:pre-wrap;
  .title :first-child{
    display: inline-block;
  }
}
</style>