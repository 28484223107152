<template>
	<div :id="'TopicInfo' + topicData.id" class="BCT">
		<template v-if="optionsData.isAnswer">
			<div v-if="!alone">
				<span>{{ topicData.quest_num_title }}</span>
				<span>（{{ topicData.grade }}分）</span>
			</div>
			<div class="topicTitle" style="margin-left: 20px;">
				<span>{{ topicData.qid }}</span>
				<span>（编程题）</span>
				<span class="title" v-html="topicData.title"></span>
				<span>（{{topicData.real_grade?topicData.real_grade:topicData.grade}}分）</span>
			</div>
			<div style="margin-bottom: 1rem;margin-left: 20px;" v-code-html="topicData.description"></div>
			<div class="analysis-box" style="display: flex">
				<div class="topicTitle">我的答案：</div>
				<div style="flex: 1">
					<div class="language">
						<el-select v-model="topicData.language" disabled size="mini" style="width: 30%">
							<el-option v-for="item in languages" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<CodeEditor :id="'CodeEditor' + topicData.id" v-model="topicData.myAnswer" readonly read-only
						class="CodeEditor" ref="CodeEditor"></CodeEditor>
					<!-- <CodeEditor :id="'CodeEditor'+topicData.id" v-model="topicData.myAnswer2" readonly read-only class="CodeEditor" ref="CodeEditor"></CodeEditor> -->
				</div>
			</div>

			<div class="analysis-box" v-if="resultShow">
				<div class="sameStyle">
					答题结果：
					<div class="button" v-if="topicData.remark" :style="{
						padding: '2px 10px',
						'--color-default': topicData.remark.color,
						}">
						{{ topicData.remark.label }}
					</div>
					<el-button class="el-button-qing" plain size="mini" :loading="loading"
						@click="click_record(topicData.log_id)" style="margin-left: 20px; padding: 7px 7px"
						v-if="topicData.remark && topicData.remark.label === 'Wait'"
						icon="el-icon-refresh">点击刷新</el-button>
				</div>
			</div>
			
			<div class="analysis-box">
				<div class="sameStyle">
					答案解析：
					<span v-code-html="topicData.analysis" v-if="topicData.analysis"></span>
					<span v-else style="color: #979797">暂无解析</span>
				</div>
			</div>
		</template>
		<div v-else style="display: flex; justify-content: space-between; margin-left: 20px;margin-top: 20px">
			<div style="width: 49%; overflow-y: scroll; height: 540px">
				<div v-if="!alone">
					<span>{{ topicData.quest_num_title }}</span><span> ({{ topicData.grade }}分)</span>
				</div>
				<div class="topicTitle">
					<span>{{ topicData.qid || topicData.id }}</span>
					<span>（编程题）</span><span class="title" v-html="topicData.title"></span>
				</div>
				<div style="display: flex;align-items: center;margin:10px 0;">
					<div class="tagbox" style="margin-right: 40px;" v-if="topicData.diff_title">
						<div class="left">
							<i class="el-icon-c-scale-to-original"></i>
						</div>
						<div style="color: #008992;margin-left: 6px;">
							{{ topicData.diff_title }}
						</div>
					</div>
					<div class="tagbox" style="margin-right: 40px;" v-if="topicData.size_limit">
						<div class="left">
							<i class="el-icon-takeaway-box"></i>
						</div>
						<div style="color: #008992">{{ topicData.size_limit }}MB</div>
					</div>
					<div class="tagbox" v-if="topicData.time_limit">
						<div class="left">
							<i class="el-icon-timer"></i>
						</div>
						<div style="color: #008992;margin-left: 16px;">{{ topicData.time_limit }}s</div>
					</div>
				</div>
				<div>
					<div class="use_case">题目描述：</div>
					<div style="margin-bottom: 0.5rem" v-code-html="topicData.description"></div>
				</div>
				<div>
					<div class="use_case">输入格式：</div>

					<div class="qzhh" v-code-html="topicData.text_input"></div>
				</div>
				<el-divider></el-divider>
				<div>
					<div class="use_case">输出格式：</div>
					<div class="qzhh" v-code-html="topicData.text_out"></div>
				</div>
				<el-divider></el-divider>
				<div v-for="(item, index) in (topicData.use_cases || []).filter(
            (v) => v.is_show + '' === '1'
          )" :key="index" class="use_cases">
					<div style="display: flex; justify-content: space-between">
						<div style="  width: 48%">
							<div style="display: flex;justify-content: space-between;align-items: center;"
								class="use_case">样例{{ index + 1 }}输入：
								<div class="copyText" v-clipboard:copy="item.input" v-clipboard:success="copyText"
									:data-clipboard-text="item.input">复制</div>
							</div>
							<div class="qzhh copybox">{{ item.input }}</div>
						</div>
						<div style="width: 48%;padding-right: 4px;">
							<div style="display: flex;justify-content: space-between;align-items: center;"
								class="use_case">样例{{ index + 1 }}输出：
								<div class="copyText" v-clipboard:copy="item.result" v-clipboard:success="copyText">复制
								</div>
							</div>
							<div class="qzhh copybox">{{ item.result }}</div>
						</div>
					</div>
					<!-- <el-divider></el-divider> -->
					<template v-if="item.explain">
						<el-divider></el-divider>
						<div class="use_case">样例{{ index + 1 }}解释：</div>
						<div class="qzhh"><span v-code-html="item.explain"></span></div>
					</template>
				</div>
				<template v-if="topicData.other_supply">
					<el-divider></el-divider>
					<div class="use_case">说明提示：</div>
					<div class="qzhh">
						<span v-code-html="topicData.other_supply"></span>
					</div>
				</template>
			</div>
			<div style="width: 49%">
				<div class="language">
					<el-select v-model="answer.language" placeholder="请选择语言" size="mini" style="width: 30%"
						@change="handleEmit('change', ...arguments)">
						<!-- <el-select v-model="answer.language" placeholder="请选择语言"  size="mini" style="width:30%" @change="change"> -->
						<el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<CodeEditor :id="'CodeEditor' + topicData.id" v-model="answer.answer" class="CodeEditor"
					ref="CodeEditor" @change="handleEmit('change', ...arguments)"></CodeEditor>
				<!-- <CodeEditor :id="'CodeEditor'+topicData.id" v-model="answer.answer" class="CodeEditor" @change="codeChange" ref="CodeEditor"></CodeEditor> -->
			</div>
		</div>
		<!-- <TopicOperate :id="topicData.id" :topic_data="topicData" :title="topicData.title" ></TopicOperate> -->
	</div>
</template>

<script>
	import clipboard from 'clipboard';
	import CodeEditor from "@/components/CodeEditor/CodeEditor.vue";
	import TopicOperate from "@/components/TopicOperate/TopicOperate.vue";
	import {
		d_record,
		options
	} from "@/api/topic";
	export default {
		model: {
			prop: "value",
			event: "change",
		},
		props: {
			value: {
				type: Object,
				default () {
					return {};
				},
			},
			alone: {
				default: () => false,
			},
			optionsData: {
				topicData: {},
				isAnswer: true,
			},
			discussShow: {
				default: () => false,
			},
			// 是否显示判题结果
			resultShow: {
				default: () => false,
			},
			lastanswer: {
				default: () => '',
			},
			stagingAnswer2: {
				default: () => '',
			},
		},
		components: {
			CodeEditor,
			TopicOperate,
		},
		data() {
			return {
				loading: false,
				languages: [],
				answer: {
					language: 0,
					answer: "",
				},
				// obj: {
				//   language:0,
				//   answer:'',
				// },
				topicData: {},
				defaultValue: "",
				IntervalIndex: null,
				openCode: false, //代码编辑器显隐
				arr: [],
				flag: false,
				lastProAnswer: this.lastanswer,
				laststagingAnswer: this.stagingAnswer2,//暂存的作业答案

			};
		},
		created() {
			console.log("created")
			this.flag = true
			console.log(this.lastanswer, "lastanswer")
			console.log(this.laststagingAnswer, "laststagingAnswer")
			// this.lastProAnswer=this.lastanswer
			this.topicData = this.optionsData.topicData || {};
			// console.log(this.topicData, " this.topicData~~~~~~~~~~~~~~");
			console.log(this.value, "valueeee")
			if (!this.value.answer && this.lastanswer) {
				this.value.answer = this.lastanswer
			}
			if (!this.value.answer && this.stagingAnswer2) {
				this.value.answer = this.stagingAnswer2
			}
			// this.answer = this.initValue(this.value);
			// console.log(this.value,"valuevalue");
			// console.log(this.answer,"answeranswer");

			// console.log(this.answer,"this.anser");
			// options({}).then(res=>{
			//   this.languages=res.data.language.list
			//   this.answer.language = this.getLanguageValue(this.answer.language);
			// }).finally(()=>{
			//   this.$emit('change' , this.answer);
			// })
			this.getOptions();
		},
		mounted() {
			// this.topicData = this.optionsData.topicData || {}
			// console.log( this.topicData," this.topicData this.topicData");
			// this.answer = this.initValue(this.value);
			// this.setvalue(this.lastanswer);
			this.click_record(this.topicData.log_id, this.topicData.id);
		},
		beforeDestroy() {
			console.log('销毁前')
			if (this.IntervalIndex) {
				clearTimeout(this.IntervalIndex);
				this.IntervalIndex = null;
			}
		},
		destroyed() {
			if (this.IntervalIndex) {
				clearTimeout(this.IntervalIndex);
				this.IntervalIndex = null;
			}
		},
		watch: {
			"optionsData.topicData": {
				immediate: true,
				deep: true,
				handler(v) {
					if (
						v.log_id &&
						v.id &&
						(this.topicData.log_id !== v.log_id || this.topicData.id !== v.id)
					) {
						this.click_record(v.log_id, v.id);
					}
					this.topicData = this.optionsData.topicData || {};
				},
			},
			value: {
				immediate: true,
				deep: true,
				handler(v) {
					console.log(v, "传进来的vvv");
					// v.answer='1144368435'
					let value = this.initValue(v);
					// console.log(value, "value")
					// console.log(this.flag, "111")
					// console.log(this.lastProAnswer,"this.lastProAnswer")
					// if(!value.answer){
					//   value.answer=this.lastanswer
					//   // console.log(1)
					//   // value.answer=this.lastProAnswer
					//   // this.flag=false
					// }
					if (!value.answer && this.flag) {
						// console.log(555)
						// value.answer=this.lastanswer
						value.answer = this.lastanswer
						if(this.stagingAnswer2){
							value.answer = this.stagingAnswer2
						}
						this.flag = false
					} else {
						this.lastProAnswer = ""
					}
					let {
						language,
						answer
					} = this.answer;
					// if(!this.answer.answer){
					// this.$emit('changelastanser' ,  this.answer.answer);
					// }
					// console.log(this.answer, " this.answer111");
					if (language !== value.language || answer !== value.answer) {
						this.answer = value;
						// this.answer.language=1
						console.log(this.answer, "更换后anser");
						this.$emit('change', this.answer);
					}
				},
			},
		},
		methods: {
			// 复制样例
			copyText() {
				this.$message.success("复制成功");
			},

			getOptions() {
				options({}).then((res) => {
					// console.log(res,"resssssss");
					this.languages = res.data.language.list;
					// console.log(this.languages,"语言列表~~~~~~~")
					// console.log(this.answer.language,"this.answer.language")
					this.answer.language = this.getLanguageValue(this.answer.language);
				});
				// .finally(()=>{
				//   this.$emit('change' , this.answer);
				// })
			},
			initValue(value) {
				value =
					value instanceof Object ?
					value :
					{
						answer: value !== null && ["number", "string"].indexOf(typeof value) > -1 ?
							value + "" :
							"",
					};
				let answer = value.answer === undefined || value.answer === null ||
					value.answer === '' ?
					null :
					value.answer.toString()
				let dataObj = {
					language: this.getLanguageValue(value.language),
					// language:value.language,
					answer: answer ? answer.trim() : null,
					// answer: answer ? answer.trim() : '',
					// quest_id: this.value.quest_id
				};
				return dataObj
			},
			getLanguageValue(v) {
				v = v === undefined ? null : v;
				var index = -1;
				if ((v || v === 0) && this.languages.length > 0) {
					index = this.languages.map((d) => d.value + "").indexOf(v + "");
					if (index === -1) {
						index = this.languages.map((d) => d.label + "").indexOf(v + "");
					}
				}
				if (index === -1) {
					v = (
						(this.languages.filter((d) => d.value == 1) ||
							this.languages || [])[0] || {
							value: null
						}
					).value;
				} else {
					v = this.languages[index].value;
				}
				return v;
			},
			handleEmitChange(e) {
				console.log(e, "aa")
				console.log(this.answer, "~~~~~~~~~~~");
				this.$emit("change", this.answer);
			},
			// codeChange(value){
			//   this.obj.answer=value
			// },
			// change(e){
			//   console.log(e,"eeeeeeee");
			//   this.obj.language=e
			// },
			submit() {
				// this.obj.quest_id=this.value.quest_id
				console.log("确定的提交答案");
				this.$message.success("保存成功");
				this.$emit("change", this.answer);
			},
			setValue(e) {
				console.log(e, "888888")
				console.log(this.answer.answer, "this.answer.answer")
				// if(this.answer.answer=""){
				//   e=""
				// }
				// this.value.answer=e
				// this.answer.answer=e
			},
			click_record(log_id, id, timeout) {
				if (this.IntervalIndex) {
					clearTimeout(this.IntervalIndex);
					this.IntervalIndex = null;
				}
				log_id = log_id || this.topicData.log_id;
				id = id || this.topicData.id;
				if (
					this.optionsData.isAnswer &&
					log_id &&
					id &&
					this.topicData.remark &&
					(this.topicData.remark || {}).label === "Wait"
				) {
					this.$emit("wait", {
						id,
						status: 0
					});
					timeout = parseInt(
						timeout || this.$config("program.programJudgeMaxTime") || 5
					);
					this.get_d_record(log_id, id, timeout);
				}
			},
			get_d_record(log_id, id, timeout) {
				this.loading = true;
				d_record({
						log_id,
						id
					})
					.then((res) => {
						let datas = res.data.info;
						if ((datas.remark || {}).label === "Wait") {
							this.IntervalIndex = setTimeout(() => {
								this.click_record(log_id, id, timeout);
							}, timeout * 1000);
						} else {
							let topicData = {
								...this.topicData
							};
							topicData.remark = datas.remark;
							topicData.language = datas.lang;
							topicData.my_grade = datas.grade;
							this.topicData = topicData;
							// this.$emit('wait',{id,status:1});
							this.$emit("update", {
								...this.optionsData,
								topicData: topicData
							});
							// this.$emit("updateData");
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>

<style lang="less" scoped>
	::v-deep {
		.el-divider--horizontal {
			margin: 10px 0;
		}
	}

	.use_cases {
		margin: 10px 0;
	}

	.use_case {
		color: #00957e;
		font-size: 16px;

		font-weight: 600;
		line-height: 38px;
	}

	.language {
		// text-align: right;
		margin: 0.5rem 0;
	}

	.BCT {
		.CodeEditor {
			margin-bottom: 20px;
			height: 500px;
		}
	}

	.topicType {
		margin: 1rem 0 1rem 1rem;
		font-size: 18px;
		color: #00957e;
	}

	.topicTitle {
		color: #00957e;
		font-size: 16px;
		font-weight: 600;
		line-height: 38px;

		.title :first-child {
			display: inline-block;
		}
	}

	.tagbox {
		display: flex;
		align-items: center;
		width: 80px;
		height: 30px;
		background: #e8feff;
		border-radius: 15px;
		font-size: 14px;

		.left {
			width: 30px;
			border-radius: 50%;
			text-align: center;
			line-height: 30px;
			height: 30px;
			background: #00957e;
			color: #ffffff;
		}
	}

	.copybox {
		width: 100%;
		border: 1px solid #ddd;
		padding: 6px;
		box-sizing: border-box;
		background: #f8f8f8;
		border-radius: 3px;
		overflow: auto;
	}

	.copyText {
		font-size: 13px;
		font-weight: normal;
		color: #00957e;
		cursor: pointer;
	}

	/* 滚动条轨道样式 */
	::-webkit-scrollbar {
		width: 4px !important;
		/* 设置滚动条宽度 */
	}

	/* 滚动条滑块样式 */
	::-webkit-scrollbar-thumb {
		background-color: #888;
		/* 设置滑块背景颜色 */
		border-radius: 4px;
		/* 设置滑块圆角 */
	}

	.sameStyle {
		color: #00957e !important;
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 38px !important;
	}
</style>