<template>
  <codemirror  ref="myCm"  :options="cmOptions" v-model="content"  @changes="handleEmit('changes' , ...arguments)" class="code" ></codemirror>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import "codemirror/theme/dracula.css"
require("codemirror/mode/python/python.js")
require('codemirror/addon/fold/foldcode.js')
require('codemirror/addon/fold/foldgutter.js')
require('codemirror/addon/fold/brace-fold.js')
require('codemirror/addon/fold/xml-fold.js')
require('codemirror/addon/fold/indent-fold.js')
require('codemirror/addon/fold/markdown-fold.js')
require('codemirror/addon/fold/comment-fold.js')
require('codemirror/addon/fold/comment-fold.js')
// require('codemirror/addon/display/autorefresh');
export default {
  components: {codemirror},
  model: {
    prop: 'value',
    event: 'change'
  },
  props:{
    id:'',
    value:{
      type:String,
      default:()=>''
    },
    defaultValue:{
      type:String,
      default:()=>''
    },
    options:{
      type:Object,
      default:()=>{return {};}
    },
    readOnly:{
      type:Boolean,
      default:()=>{return false;}
    }
  },
  data () {
    return {
      content: this.value === null ? this.defaultValue : this.value,
      cmOptions: {
        tabSize: 4,// tab的空格个数
        theme: 'dracula',//主题样式
        lineNumbers: true,//是否显示行数
        lineWrapping: true, //是否自动换行
        autoRefresh: true,
        viewportMargin:Infinity,
        styleActiveLine: true,//line选择是是否加亮
        matchBrackets: true,//括号匹配
        readOnly: false//只读
      },
      show:false
    }
  },
  watch: {
    options:{
      deep:true,
      immediate:true,
      handler(v){
        this.cmOptions = Object.assign(this.cmOptions , v);
      }
    },
    readOnly(v){
      this.cmOptions.readOnly = v;
    },
   
    value(a){
      if(a === undefined || a === null || a.toString().trim() === ''){
        this.content = this.defaultValue;
      }else if(this.$tool.obj_equal(this.content , a) === false){
        this.content = a;
      }
    }
  },
  mounted() {
    this.cmOptions = Object.assign(this.cmOptions ,{readOnly: this.readOnly} , this.options);
  },
  methods:{
    openCode(){
      setTimeout(() => {
        console.log(this.$refs.myCm,'触发到最底层~~~~~~~~~~~ss')
        this.$refs.myCm.refresh()
          // this.$refs.myCm.refresh()
      }, 50);
    },
    handleEmitChanges(){
      // console.log(this.content,"this.contentthis.content");
      // console.log(...arguments,"...arguments");
      this.$emit('change' , this.content)
      this.$emit('changes' , ...arguments)
    },
  }
};
</script>

<style lang="less" scoped>
.codefence > .editor {
  min-height: 280px;
}
</style>
