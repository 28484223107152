<template>
  <div class="DUOXT" :id="'TopicInfo'+topicData.id" style="margin: 0 20px;">
    <div>
      <div v-if="!alone"><span>{{topicData.quest_num_title}}</span><span>（{{topicData.grade}}分）</span></div>
      <div class="topicTitle"><span>{{ topicData.qid }}</span><span>（不定项选择题）</span><span class="title" v-html="topicData.title"></span><span>（{{topicData.real_grade?topicData.real_grade:topicData.grade}}分）</span></div>
      <div style="margin-bottom: 1rem;" v-code-html="topicData.description"></div>
    </div>
    <template v-if="optionsData.isAnswer">
      <el-checkbox-group v-model="topicData.answer" disabled v-if="(topicData.options || []).length">
        <div class="group-item" v-for="(v, i) in topicData.options" :key="i">
          <el-checkbox :label="v.code" v-if="v.title_type+''!=='1'">
            <span class="el-checkbox__label">{{ v.code }}、</span>
            <span v-html="v.title"></span>
          </el-checkbox>
          <el-checkbox :label="v.code" v-else>
            <span class="el-checkbox__label">{{ v.code }}、</span>
            <el-image style="width: 150px;" :src="v.title"></el-image>
          </el-checkbox>
        </div>
      </el-checkbox-group>
      <div class="analysis-box" v-if="resultShow">
        <div>参考答案：{{ (topicData.answer || []).join('，') }}</div>
        <div>我的答案：{{ (value || []).join('，') }}
          <template v-if="topicData.remark">
            <i class="el-icon-check check_record" v-if="topicData.remark.label==='对'||topicData.remark==='对'"></i>
            <i class="el-icon-close close_record" v-if="topicData.remark.label==='错'||topicData.remark==='错'"></i>
          </template>
        </div>
      </div>
      <div class="analysis-box" v-if="!(topicData.type+'' === '3')">
        <div>答案解析：
          <span v-code-html="topicData.analysis" v-if="topicData.analysis"></span>
          <span v-else style="color:#979797">暂无解析</span>
        </div>
      </div>
    </template>
    <template v-else>
      <el-checkbox-group v-model="checkListAnswer" @change="handleEmit('change' , ...arguments)">
        <div class="group-item" v-for="(v, i) in topicData.options" :key="i">
          <el-checkbox :label="v.code" v-if="v.title_type+''!=='1'">
            <span class="el-checkbox__label">{{ v.code }}、</span>
            <span v-html="v.title"></span>
          </el-checkbox>
          <el-checkbox :label="v.code" v-else>
            <span class="el-checkbox__label">{{ v.code }}、</span>
            <el-image style="width: 150px;" :src="v.title"></el-image>
          </el-checkbox>
        </div>
      </el-checkbox-group>
    </template>
    <TopicOperate :id="topicData.id" :topic_data="topicData" :title="topicData.title" v-if="discussShow && !(topicData.type+'' === '3')"></TopicOperate>
  </div>
</template>

<script>
import TopicOperate from "@/components/TopicOperate/TopicOperate.vue"
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value:{
      default(){return null;}
    },
    alone: {
      default: () => false
    },
    optionsData: {
      topicData: {},
      isAnswer: true,
    },
    stagingAnswer2: {
				default: () => '',
			},
      // 暂存传进来的
      stagingAnswerArr: {
				default: () => '',
			},
      // 是否显示判题结果
			resultShow: {
				default: () => false,
			},
    discussShow: {
      default: () => false
    }
  },
  components: { TopicOperate },
  data () {
    return {
      checkList: [],
      checkListAnswer: []
    }
  },
  computed: {
    topicData () {
      console.log(this.optionsData.topicData,"this.optionsData.topicDatathis.optionsData.topicData");
      return this.optionsData.topicData || {}
    }
  },
  watch: {
    value (val) {
      console.log(val,"valllll")
      if(val && val !== this.checkListAnswer){
        let checkListAnswer;
        if(val instanceof Array){
          checkListAnswer = [...val];
        }else{
          checkListAnswer = val.split('、');
        }
        if(this.$tool.obj_equal(checkListAnswer , this.checkListAnswer)){
          this.checkListAnswer = checkListAnswer;
        }
      }
      this.$emit("change", this.checkListAnswer);
    },
  },
  created () {
    console.log(this.value,"value");
    this.handleEmitChange()
    if(this.optionsData.topicData.last_answer){
      console.log('111')
      this.checkListAnswer=JSON.parse(this.optionsData.topicData.last_answer);
      console.log(this.checkListAnswer,"this.checkListAnswer")
    }
    if(this.stagingAnswer2){
      console.log(this.stagingAnswer2,'checkListAnswer')
      this.checkListAnswer=this.stagingAnswer2.split('、');
      console.log(this.checkListAnswer,"checkListAnswer")
    }
    if(this.stagingAnswerArr){
      console.log(this.stagingAnswerArr,'一题多问多选')
      this.checkListAnswer=this.stagingAnswerArr.split('、');
      console.log(this.checkListAnswer,"t一题多问答案数组")
    }
    if(this.value){
      if(this.value instanceof Array){
        this.checkListAnswer = [...this.value];
      }else{
        this.checkListAnswer = this.value.split('、');
      }
    }
  },
  methods:{
    handleEmitChange() {
      console.log(this.checkListAnswer,"~~~~~~~~~~~");
      this.$emit("change", this.checkListAnswer);
    },
  }
};
</script>

<style lang="less" scoped>
.DUOXT {
  ::v-deep {
    .el-checkbox {
      display: flex;
      align-items: center;
    }
    .el-checkbox__label {
      display: flex;
      align-items: center;
    }
    .el-checkbox,.el-checkbox .el-checkbox__label{
      align-items: self-start;
      line-height: 1.2;
    }
  }
  .filling {
    input {
      border: 0;
      color: #606266;
      font-size: 14px;
    }
    input:focus {
      outline: none;
    }
  }
  .topicType {
    margin: 3rem 0;
    font-size: 18px;
    color: #00957e;
  }
  .topicTitle {
    margin: 1rem 0;
    font-size: 16px;
  color: #00957e;
    font-weight: 600;
    line-height: 38px;
    white-space: pre-wrap;
    .title :first-child{
      display: inline-block;
    }
  }
  .group-item {
    margin: 1rem 0;
  }
}
</style>