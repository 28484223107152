<template>
  <div class="operate">
    <template v-if="operate && showoperate">
      <div class="feedback" @click="handleCommand('feedback' , topic_data.type == 3 ? topic_data.id+' - '+title : title , '题目')">
        <i class="el-icon-edit-outline"></i>
        <span>反馈</span>
      </div>
      <!-- 讨论 -->
      <el-popover placement="bottom-end" width="760" trigger="click" @show="ShowPopover">
        <div class="feedback" slot="reference">
          <i class="el-icon-chat-dot-round"></i><span>讨论</span>
        </div>
        <div class="evaluate" v-loading="replyLoading">
          <p style="text-align: center;color:#bcbcbc" v-if="!commentList.length">暂无评论</p>
          <div class="evaluate-item" v-for="(v, i) in commentList" :key="i">
            <div class="user">
              <el-avatar size="large" :src="v.user_headimg"></el-avatar>
              <div class="user-name">{{ v.user_nickname }}</div>
            </div>

            <div class="text">
              <div>
                <div class="text-user" v-if="v.r_user_id">
                  @{{ v.r_nickname }}
                </div>
                {{ v.content }}
              </div>

              <div class="time">
                <span>{{ v._create_time }}</span>
                <span class="reply">
                  <el-popover placement="top-end" width="500" trigger="hover">
                    <div class="ipt">
                      <el-input v-model="itemValue" placeholder="请输入内容" type="textarea" maxlength="200" :rows="3"></el-input>
                    </div>

                    <div style="text-align: right; margin-top: 20px">
                      <!-- <el-button type="primary" size="mini" @click="replyShow=false">取消</el-button> -->
                      <el-button type="primary" size="mini" @click="reply(v)">确定</el-button>
                    </div>
                    <div slot="reference" class="buttom">
                      <!-- <span v-if="user_id == v.user_id">自己的评论</span> -->
                      <el-button type="text" v-if="user_id != v.user_id" style="color: #00957e;">回复</el-button>
                    </div>
                  </el-popover>

                  <!-- <div slot="reference" class="buttom">
                  <span v-if="user_id == v.user_id">自己的评论</span>
                  <el-button type="text" @click="reply(v)" v-if="user_id != v.user_id">回复</el-button>
                </div> -->
                </span>
              </div>
            </div>
          </div>
          <div class="pagination">
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="currentChange" :page-size="page_size" :current-page="page" hide-on-single-page>
            </el-pagination>
          </div>
          <div class="publish">
            <el-input placeholder="我来说一句" v-model="publishValue" size="small"></el-input>
            <el-button style="color: #fff" @click="publish">发表</el-button>
          </div>
        </div>
      </el-popover>
    </template>
    <template v-else-if="showoperate">
        <div class="hint">题目不存在或已被删除</div>
    </template>
  </div>
</template>

<script>
import {comment_list, comment_submit} from "@/api/topic.js"
export default {
  components: {  },
  props: {
    id: {
      default: () => ''
    },
    title: {
      default: () => ''
    },
    topic_data: {
      default: () => {
        return {
          topic_id: '',
          quest_num_title: '',
        }
      }
    }
  },
  data () {
    return {
      user_id: localStorage.getItem("user_id") || "",
      textarea: "",
      replyLoading: false,
      publishValue: '',
      itemValue: '',
      page: 1,
      page_size: 4,
      total: 0,
      commentList: [],
      operate: true,
	    showoperate:true
    }
  },
  created () {
	  this.showoperate = !((this.topic_data || {}).pid > 0);
  },
  methods: {
    ShowPopover(){
      if(this.showoperate && this.commentList.length === 0){
        this.getCommentList();
      }
    },
    currentChange (e) {
      this.page = e
      this.getCommentList()
    },
    //讨论列表
    getCommentList () {
      comment_list({
        id: this.id,
        page: this.page,
        page_size: this.page_size
      }).then(({ data }) => {
        this.commentList = data.data
        this.total = data.total
      }).catch(err => {
       
        this.operate=false
      })
    },

    // 发表评论
    publish () {
      if (!this.publishValue) {
        return this.$message.error("内容不能为空")
      }
      this.replyLoading = true
      comment_submit({
        id: this.id,
        content: this.publishValue,
      })
        .then(() => {
          this.$message.success("评论成功")
          this.page = 1
          this.getCommentList()
        }).finally(() => {
          this.publishValue = ""
          this.replyLoading = false
        })
    },
    // 回复评论
    reply (v) {

      if (!this.itemValue) {
        return this.$message.error("内容不能为空")
      }
      this.replyLoading = true
      comment_submit({
        id: this.id,
        content: this.itemValue,
        pid: v.id,
      })
        .then(() => {
          this.$message.success("回复成功")
          this.getCommentList()
        }).finally(() => {
          this.itemValue = ""
          this.replyLoading = false
        })
    },
  },
};
</script>

<style lang="less" scoped>
  .hint{
    font-size: 14px;
    color: #979797;
  }
.evaluate {

  // height: 26rem;
  padding: 2rem;
  overflow: auto;
  .pagination {
    text-align: center;
    margin: 2.5rem 0;
  }
  /deep/ .publish {
    display: flex;
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      border-radius: 40px 0 0 40px;
      border: 1px solid #d4d4d4;
      border-right: none;
    }

    .el-button {
      height: 32px !important;
      width: 53px;
      padding: 0;
      text-align: center;
      box-sizing: border-box;
      border-radius: 0 40px 40px 0;
      border: 1px solid #00957e;
      border-left: none;
      background: #00957e;
      .el-icon-search {
        color: #fff;
      }
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #00957e;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #00957e;
      border-color: #00957e;
    }
  }
  .evaluate-item {
    display: flex;
    align-items: center;
    border: 1px solid #bcbcbc;
    border-top: 0;
    &:nth-child(1) {
      border-top: 1px solid #bcbcbc;
    }
    padding: 1% 4% 1% 3.5%;

    .text {
      flex: 8;
      // width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: 1rem;
      background: #ffffff;
      padding: 1rem 1rem 0.5rem;
      font-size: 14px;
      .text-user {
        color: #525252;
        margin-bottom: 0.5rem;
      }
    }
    .user {
      text-align: center;
      font-size: 10px;
    }
    .time {
      display: flex;
      align-items: flex-end;
      text-align: right;
      color: #525252;
      font-size: 12px;
      margin-left: 0.5rem;
      .buttom {
        margin-top: 2rem;
        .el-button {
          padding: 0;
        }
      }
      .reply {
        margin-left: 0.8rem;
        cursor: pointer;
      }
    }
  }
}
.feedback-content {
  padding: 1rem 2rem;
  ::v-deep {
    .el-input__inner {
      border-radius: 40px;
      border: 1px solid #d4d4d4;
      height: 40px;
      line-height: 40px;
    }
    .el-textarea__inner:focus {
      border: 1px solid #d4d4d4;
    }
  }
}
.save-btn {
  height: 2rem;
  margin-top: 1rem;
}
.save,
.cancel {
  float: right;
  bottom: 2rem;
  right: 2rem;
  width: 40px;
  cursor: pointer;
  background: #00957e;
  border-radius: 2px;
  color: #fff;
  padding: 6px 20px;
  text-align: center;
}
.cancel {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 2px;
  border: 1px solid #979797;
  padding: 5px 20px;
  margin-right: 0.5rem;
}
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .feedback {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 14px;
  }
  .el-icon-edit-outline,
  .el-icon-chat-dot-round {
    font-size: 20px;
    margin-right: 0.2rem;
  }
}
</style>
